/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import n from "./RandomLCG.js";
const t = 1.5,
  e = 1 / t,
  r = .5;
function l(n, t) {
  if (!t) return n;
  for (const e of t) n.push(e);
  return n;
}
function o(n) {
  return n;
}
function u(n, t = o) {
  if (!n || 0 === n.length) return;
  let e = n[0],
    r = t(e);
  for (let l = 1; l < n.length; ++l) {
    const o = n[l],
      u = Number(t(o));
    u > r && (r = u, e = o);
  }
  return e;
}
function f(n, t = o) {
  return u(n, n => -t(n));
}
function i(n, t) {
  return t ? n.filter((n, e, r) => r.findIndex(t.bind(null, n)) === e) : Array.from(new Set(n));
}
function c(n, t, e) {
  if (null == n && null == t) return !0;
  if (null == n || null == t || n.length !== t.length) return !1;
  if (e) {
    for (let r = 0; r < n.length; r++) if (!e(n[r], t[r])) return !1;
  } else for (let r = 0; r < n.length; r++) if (n[r] !== t[r]) return !1;
  return !0;
}
function s(n, t) {
  let e = n.length !== t.length;
  e && (n.length = t.length);
  for (let r = 0; r < t.length; ++r) n[r] !== t[r] && (n[r] = t[r], e = !0);
  return e;
}
function h(n, t, e) {
  let r, l;
  return e ? (r = t.filter(t => !n.some(n => e(n, t))), l = n.filter(n => !t.some(t => e(t, n)))) : (r = t.filter(t => !n.includes(t)), l = n.filter(n => !t.includes(n))), {
    added: r,
    removed: l
  };
}
function a(n, t, e) {
  return n && t ? e ? n.filter(n => t.findIndex(t => e(n, t)) > -1) : n.filter(n => t.includes(n)) : [];
}
function d(n) {
  return n && "number" == typeof n.length;
}
function g(n, t) {
  const e = n.length;
  if (0 === e) return [];
  const r = [];
  for (let l = 0; l < e; l += t) r.push(n.slice(l, l + t));
  return r;
}
function m(n, t) {
  void 0 === t && (t = n, n = 0);
  const e = new Array(t - n);
  for (let r = n; r < t; r++) e[r - n] = r;
  return e;
}
function p(n, t, e) {
  const r = n.length;
  let l = 0,
    o = r - 1;
  for (; l < o;) {
    const e = l + Math.floor((o - l) / 2);
    t > n[e] ? l = e + 1 : o = e;
  }
  const u = n[l];
  return e ? t >= n[r - 1] ? -1 : u === t ? l : l - 1 : u === t ? l : -1;
}
class M {
  constructor() {
    this.last = 0;
  }
}
const w = new M();
function x(n, t, e, r) {
  r = r || w;
  const l = Math.max(0, r.last - 10);
  for (let u = l; u < e; ++u) if (n[u] === t) return r.last = u, u;
  const o = Math.min(l, e);
  for (let u = 0; u < o; ++u) if (n[u] === t) return r.last = u, u;
  return -1;
}
function b(n, t, e, r) {
  const l = e ?? n.length,
    o = x(n, t, l, r);
  if (-1 !== o) return n[o] = n[l - 1], null == e && n.pop(), t;
}
const y = new Set();
function S(n, t, e = n.length, r = t.length, l, o) {
  if (0 === r || 0 === e) return e;
  y.clear();
  for (let f = 0; f < r; ++f) y.add(t[f]);
  l = l || w;
  const u = Math.max(0, l.last - 10);
  for (let f = u; f < e; ++f) if (y.has(n[f]) && (o?.push(n[f]), y.delete(n[f]), n[f] = n[e - 1], --e, --f, 0 === y.size || 0 === e)) return y.clear(), e;
  for (let f = 0; f < u; ++f) if (y.has(n[f]) && (o?.push(n[f]), y.delete(n[f]), n[f] = n[e - 1], --e, --f, 0 === y.size || 0 === e)) return y.clear(), e;
  return y.clear(), e;
}
function v(n, t) {
  let e = 0;
  for (let r = 0; r < n.length; ++r) {
    const l = n[r];
    t(l, r) && (n[e] = l, e++);
  }
  n.length = e;
}
function z(n, t, e) {
  const r = n.length;
  if (t >= r) return n.slice();
  const l = A(e),
    o = new Set(),
    u = [];
  for (; u.length < t;) {
    const t = Math.floor(l() * r);
    o.has(t) || (o.add(t), u.push(n[t]));
  }
  return u;
}
function A(n) {
  return n ? (j.seed = n, () => j.getFloat()) : Math.random;
}
function I(n, t) {
  const e = A(t);
  for (let r = n.length - 1; r > 0; r--) {
    const t = Math.floor(e() * (r + 1)),
      l = n[r];
    n[r] = n[t], n[t] = l;
  }
  return n;
}
const j = new n();
function C(n, t) {
  const e = n.indexOf(t);
  return -1 !== e ? (n.splice(e, 1), t) : null;
}
function F(n, t) {
  return null != n;
}
const G = [];
export { M as PositionHint, l as addMany, p as binaryIndexOf, h as difference, G as emptyArray, c as equals, v as filterInPlace, A as getRandomNumberGenerator, x as indexOf, a as intersect, d as isArrayLike, F as isSome, u as max, f as min, z as pickRandom, m as range, t as reallocGrowthFactor, e as reallocShrinkFactor, r as reallocShrinkThreshold, C as remove, b as removeUnordered, S as removeUnorderedMany, I as shuffle, g as splitIntoChunks, i as unique, s as update };